import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":_vm.isRespond ? '75%' : '62%'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"disc--title"},[_vm._v(" "+_vm._s(_vm.isRespond ? ("Respond to Disciplinary case raised by " + (_vm.disciplinary.raisedByName ? _vm.disciplinary.raisedByName : "")) : "Add Accusations")+" "),_c(VSpacer),(_vm.$route.params.no)?_c(VChip,{class:("" + (_vm.getColor(_vm.disciplinary.status)))},[_vm._v(" Status: "+_vm._s(_vm.disciplinary.status)+" ")]):_vm._e()],1),_c(VDivider),_c(VCardText,[_c(VForm,{ref:"discForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VAutocomplete,{staticClass:"mt-3",attrs:{"items":_vm.employees,"placeholder":"Select Accused Employee","persistent-placeholder":"","rules":[_vm.rules.required],"label":"Accused Employee","clearable":"","outlined":"","item-text":"first_name","item-value":"number","disabled":!_vm.isEdit || _vm.isRespond},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" how_to_reg ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c(VAvatar,{staticClass:"primary--text text--darken-4 mr-3",attrs:{"color":"primary lighten-4","size":"30"}},[_vm._v(" "+_vm._s(("" + (item.first_name[0] || "") + (item.last_name[0] || "")))+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" "),_c(VChip,{staticClass:"primary--text text--darken-4 mx-2",attrs:{"left":"","x-small":"","color":"primary lighten-4"}},[_vm._v(" "+_vm._s(item.number)+" ")])],1)]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.email))])],1),_c(VListItemAction,[_c(VChip,{staticStyle:{"position":"absolute","right":"1em","top":"1em"},attrs:{"small":"","label":"","color":"primary lighten-4"}},[_vm._v(" "+_vm._s(item.jobTitle)+" ")])],1)],1)]}}]),model:{value:(_vm.formData.accused),callback:function ($$v) {_vm.$set(_vm.formData, "accused", $$v)},expression:"formData.accused"}})],1),_c('DisciplinaryLines',{attrs:{"disciplinaryLine":_vm.formData.disciplinaryLine,"disciplinary":_vm.disciplinary,"isEdit":_vm.isEdit,"isRespond":_vm.isRespond,"user":_vm.user}})],1),_c(VDivider),_c(VCardActions,{staticClass:"disc--actions"},[_c(VBtn,{staticClass:"deep-orange text-capitalize lighten-4 deep-orange--text text--darken-4",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-close")]),_vm._v(" Close ")],1),_c(VSpacer),(_vm.isEdit && !_vm.isRespond)?_c(VBtn,{staticClass:"primary text-capitalize",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.saveDisciplinary()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Save ")],1):_vm._e(),(_vm.respondee)?_c(VBtn,{staticClass:"primary text-capitalize",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.respond()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("waving_hand")]),_vm._v(" Respond ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }